.Popup__container {
    width: 500px;
    height: calc(100% - 24px);
    background: #fff;
    border-radius: 12px;
    position: absolute;
    z-index: 9999;
    top: 12px;
    right: 12px;
    padding: 24px;
    overflow: auto;
}

.Popup__header {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 24px;
}

.Popup__close {
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.Popup__title {
    font-size: 24px;
    font-weight: 600;
}

.Popup__image {
    width: 100%;
    height: auto;
    border-radius: 12px;
    margin-bottom: 24px;
}

@keyframes Popup__container--popdown {
    from {
        opacity: 1;
        transform: translateY(0) translateX(0);
    }
    to {
        opacity: 0;
        transform: translateY(100%) translateX(100%);
    }
}

@keyframes Popup__container--popup {
    from {
        opacity: 0;
        transform: translateY(100%) translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0) translateX(0);
    }
}