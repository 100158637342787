.Language__container {
    width: fit-content;
    background: #fff;
    border-radius: 8px;
    position: absolute;
    z-index: 9999;
    top: 24px;
    right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Language__item {
    width: fit-content;
    padding-right: 12px;
    padding-left: 12px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.Language__item .Language__flag {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 12px;
    object-fit: cover;
}