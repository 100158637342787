.Loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.Loading__image {
    width: 500px;
    height: 100px;
    animation: opa 1s linear infinite;
}

@keyframes opa {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}