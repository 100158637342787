.FullScreenPhoto__container {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.8);
}

.FullScreenPhoto__photo {
    width: 100%;
    height: 90%;
    object-fit: contain;
}

.FullScreenPhoto__close {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    cursor: pointer;
}

.FullScreenPhoto__close img {
    width: 20px;
    height: 20px;
    object-fit: cover;
}