@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

.map-container {
  width: 100vw;
  height: 100vh;
  z-index: 0;
}

.mapboxgl-ctrl a {
  display: none;

}

.marker {
  background-image: url('https://docs.mapbox.com/help/demos/custom-markers-gl-js/mapbox-icon.png');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.WWII {
  background-image: url('../public/img/wwii.png');
  background-size: cover;
  width: 39px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.historicalevent {
  background-image: url('../public/img/events.png');
  background-size: cover;
  width: 39px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.architecture {
  background-image: url('../public/img/arch.png');
  background-size: cover;
  width: 39px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.historicalperson {
  background-image: url('../public/img/people.png');
  background-size: cover;
  width: 39px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.internationalsoldiers {
  background-image: url('../public/img/fights.png');
  background-size: cover;
  width: 39px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.historyandculture {
  background-image: url('../public/img/history.png');
  background-size: cover;
  width: 39px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}


.sacral {
  background-image: url('../public/img/history.png');
  background-size: cover;
  width: 39px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}