.TimeTab__container {
    width: fit-content;
    padding-right: 12px;
    padding-left: 12px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}

.TimeTab__container--selected {
    background: rgba(0, 0, 0, 0.2);
    color: #000;
    border-radius: 8px;
}